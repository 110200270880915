import { useEffect, useMemo, useRef, useState } from "react";
import {
    ACTIONS_PARAM_NAMES_ENVELOPES,
    ActionsTypesForGuestRoutes,
    EnvelopeActionStatus,
    FIELD_TYPES,
    FINISH_LATER_BTN_CHECK,
    envelopeActionEnum,
    envelopeHeaderKey,
    normalRoutesConstant
} from "util/util.constant";
// import "./ip-form.less";
import { Button, message } from "antd";
import moment from "moment";
import { useQueryEnvelopebyPassedRoute } from "queries/envelope.queries";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { UtilService } from "util/util.service";
import { signData } from "../../redux/tabSlice";
import CustomSignatureModal from "./CustomSignatureModal";
import DraggableSignatureBox from "./DraggableSignatureBox";
import HTMLDropWrapper from "./HTMLDropWrapper";
import PrintSignatureField from "./PrintSignatureField";
import { SIGNATURE_MODAL } from "./SignatureModal";
import { set } from "lodash";

function HTMLEnvelopeFormView() {
    const getSigningLocalStorageData = useSelector(signData);
    const oneSecond = 1000;
    const oneMinute = oneSecond * 60;
    const autoSaveTime = process.env.REACT_APP_FORM_AUTOSAVE_TIME * oneMinute;
    const parser = new DOMParser();
    const pdfRef = useRef();
    const [cssSrc, setCssSrc] = useState(null);
    const [scriptSrc, setScriptSrc] = useState("ip-form/ip-form-script.js");
    const [fields, setFields] = useState([]);
    const [signaturePopup, setSignaturePopup] = useState(false);
    const [buttonDisable, setButtonDisable] = useState(false);
    const customFinishBtnLoader = useRef(false);
    const updateCloneAction = useRef(true);

    const { mutateAsync: createEnvelopeByPassedRouteMutateQuery, isLoading: updateEnvelopeByPassedLoading } =
        useQueryEnvelopebyPassedRoute();

    const wide = useWindowWide(600);

    const isDraftEnvelope = useRef(null);

    const {
        refresh_token,
        x_envelope,
        env: envelopeIdParam,
        action: actionParam,
        reviewer,
        client_api_key = "",
        send_email = false,
        restrictions = "",
        is_document_edit,
        webhookData,
        showButtons,
        data
    } = useMemo(() => UtilService.urlParameters(), [window.location.search]);

    const decodedWebhookData = webhookData && JSON.parse(atob(webhookData));
    const review = reviewer && JSON.parse(reviewer);
    const [signatureField, setSignatureField] = useState(null);
    const queryNameField = UtilService.base64toArray(data);

    const convertArrayOfQueryToObject = useMemo(
        () => queryNameField?.reduce((obj, item) => ((obj[item.queryName] = item.value), obj), {}),
        []
    );

    const signingData = useMemo(() => {
        return getSigningLocalStorageData !== null || getSigningLocalStorageData !== ""
            ? getSigningLocalStorageData
            : false;
    }, [getSigningLocalStorageData]);
    const targetElementRef = useRef(null);
    // When envelope is creating it give template data
    const { template: { attributes: templateAttribute, id: templateId, name: templateName } = {} } = signingData || {};
    // when envelope is created it give envelope data
    const { envelope: { attributes: envelopeAttribute, id: envelopId, name: envelopeName } = {} } = signingData || {};

    const { templateDetail, htmlType: templateHtmlType, type: templateType } = templateAttribute || {};
    const { envelopeDetail, template: envelopeTemplate } = envelopeAttribute || {};

    const { htmlType: envelopeHtmlType, type: envelopeType } = envelopeTemplate?.attributes || {};

    const { tabs: templateTabs } = templateDetail || {};
    const { tabs: envelopeTabs } = envelopeDetail || {};

    const tabs = templateTabs || envelopeTabs;
    const id = templateId || envelopId;
    const name = templateName || envelopeName;
    const htmlType = templateHtmlType || envelopeHtmlType || {};
    const type = templateType || envelopeType;

    const checkUserEnvelopeGeneratedFormView = useMemo(
        () => signingData && Boolean(refresh_token) && Boolean(x_envelope),
        [signingData, refresh_token, x_envelope]
    );
    const timeoutIdRef = useRef(null);

    useEffect(() => {
        // Function to be called when the target element changes
        const handleMutation = () => {
            // Do something when the target element changes
            clearTimeout(timeoutIdRef.current);

            // isAutoSaveInProgress.current = true;
            timeoutIdRef.current = setTimeout(() => {
                onFormFinish("", true, true);
            }, autoSaveTime);
        };

        // Options for the MutationObserver (configure as needed)
        const observerOptions = {
            attributes: true, // Set to true if you want to observe attribute changes
            childList: true, // Set to true if you want to observe child elements changes
            subtree: true // Set to true if you want to observe changes in the whole subtree
        };
        handleMutation();

        // Create a new MutationObserver with the callback function and options
        const observer = new MutationObserver(() => {
            handleMutation();
        });

        // Start observing the target element
        if (targetElementRef.current) {
            observer.observe(targetElementRef.current, observerOptions);
        }

        // Clean up the observer when the component is unmounted
        return () => {
            clearTimeout(timeoutIdRef.current);
            observer.disconnect();
        };
    }, []);
    useEffect(() => {
        try {
            document.body.style.background = "grey";
            const signatureModal = parser.parseFromString(SIGNATURE_MODAL, "text/html");
            const modal = signatureModal.getElementById("signatureModal");
            const modalBackdrop = signatureModal.getElementById("modalBackdrop");
            document.body.appendChild(modalBackdrop);
            document.body.appendChild(modal);
            
            if (htmlType === "PERSON") {
                // Embed CSS directly into the document for Person form
                const style = document.createElement('style');
                fetch('/statichtmlPages/person-individual-profile/person-style.css')
                    .then(response => response.text())
                    .then(css => {
                        style.textContent = css;
                        document.head.appendChild(style);
                    });
                setCssSrc(null); // Don't use external CSS for Person form
                setScriptSrc("person-individual-profile/person-main-script.js");
            } else if (htmlType === "NURSE") {
                // New Nurse form handling - similar to Person form
                const style = document.createElement('style');
                fetch('/statichtmlPages/nurse-form/nurse-style.css')
                    .then(response => response.text())
                    .then(css => {
                        style.textContent = css;
                        document.head.appendChild(style);
                    });
                setCssSrc(null);
                setScriptSrc("nurse-form/nurse-script.js");
            } else if (htmlType === "CNA") {
                setCssSrc("cna-form/style.css");
                setScriptSrc("cna-form/cna-form-script.js");
            } else if (htmlType === "OIG") {
                setCssSrc("oig-form/oig-form-style.css");             
                setScriptSrc("oig-form/oig-form-script.js");
            }
            else if (htmlType === "IPC") {
            setCssSrc("ipc-form/ipc-form-style.css");
            setScriptSrc("ipc-form/ipc-form-script.js");
            }
            else {
                setCssSrc("ip-form/ip-form.css");
                setScriptSrc("ip-form/ip-form-script.js");
            }
            
            const signatureFields = tabs?.filter((tab) => tab.type === "signature");
            setFields(signatureFields);
        } catch (error) {
            console.error("Error loading form:", error);
            message.error("Error loading form. Please check console for details.");
        }
    }, []);

    // Ensure contract_no is in the autofill fields array
    const autofillFields = [
        {
            queryName: "lastNameFirstName", 
            formName: "provider_name", 
            altNames: ["lastNameFirstName", "provider_name"],
            debug: true
        },
        {
            queryName: "careId",
            formName: "care_id",
            altNames: ["careId", "care_id"],
            debug: true
        },
        {
            queryName: "componentCode", 
            formName: "comp_code", 
            altNames: ["componentCode", "comp_code"],
            debug: true
        },
        {
            queryName: "ipcStarts",
            formName: "ipc_starts",
            altNames: ["ipcStarts", "ipc_starts"],
            isDate: true,
            debug: true
        },
        {
            queryName: "ipcEnds",
            formName: "ipc_ends",
            altNames: ["ipcEnds", "ipc_ends"],
            isDate: true,
            debug: true
        },
        {
            queryName: "dateOfBirth",
            formName: "date_of_birth",
            altNames: ["dateOfBirth", "date_of_birth"],
            isDate: true,
            debug: true
        },
        {
            queryName: "contract", 
            formName: "contract_no", 
            altNames: ["contract", "contract_no", "contractNo"],
            debug: true
        },
        {
            queryName: "company", 
            formName: "company_name", 
            altNames: ["company_name"],
            debug: true
        },
        {
            queryName: "age", 
            formName: "age", 
            altNames: ["age"],
            debug: true
        },
        {
            queryName: "lon", 
            formName: "lon_name", 
            altNames: ["lon_name"],
            debug: true
        },
        {
            queryName: "fullAddress", 
            formName: "fullAddress", 
            altNames: ["fullAddress"],
            debug: true
        },
        {
            queryName: "county", 
            formName: "county", 
            altNames: ["county"],
            debug: true
        },
        {
            queryName: "locationCode", 
            formName: "locationCode", 
            altNames: ["locationCode"],
            debug: true
        },
        {
            queryName: "currentDate",
            formName: "currentDate",
            altNames: ["currentDate", "ipc_effective_date"],
            isDate: true,
            defaultValue: moment().format("YYYY-MM-DD"), // Add default current date
            debug: true
        }
    ];

    const dateFields = ["ipcStarts", "ipcEnds", "dateOfBirth"];

    // Add this helper function near the top of the file
    const injectFormScripts = () => {
        const script = document.createElement('script');
        script.textContent = `
            function handleInputChange(inputElement) {
                if (inputElement) {
                    const updatedValue = inputElement.value;
                    inputElement.setAttribute("value", updatedValue);
                }
            }
            
            function handleCheckedButton(checkboxElement) {
                checkboxElement.setAttribute("checked", checkboxElement.checked);
            }
            
            function handleRadioButton(radioElement) {
                radioElement.setAttribute("checked", true);
            }
        `;
        document.head.appendChild(script);
    };

    // Update the useEffect that handles form initialization
    useEffect(() => {
        if (convertArrayOfQueryToObject) {
            // First inject the required scripts
            injectFormScripts();
    
            // Then wait a short moment to ensure scripts are loaded
            setTimeout(() => {
                autofillFields.forEach((field) => {
                    // Select all elements with the same name or formName
                    const elements = document.querySelectorAll(
                        `#${field.formName}, input[name="${field.formName}"], ${field.altNames
                            .map((altName) => `input[name="${altName}"]`)
                            .join(", ")}`
                    );
    
                    elements.forEach((element) => {
                        if (element) {
                            // Try all possible value sources
                            let value =
                                convertArrayOfQueryToObject[field.queryName] ||
                                convertArrayOfQueryToObject[field.formName] ||
                                field.altNames
                                    ?.map((name) => convertArrayOfQueryToObject[name])
                                    .find((v) => v) ||
                                field.defaultValue || // Add default value support
                                "";
    
                            // Format date fields if needed
                            if (field.isDate && value) {
                                try {
                                    const date = new Date(value);
                                    if (!isNaN(date.getTime())) {
                                        value = date.toISOString().split("T")[0];
                                    }
                                } catch (err) {
                                    console.warn(`Error formatting date for ${field.formName}:`, err);
                                }
                            }
    
                            element.value = value;
                            element.setAttribute("value", value);
    
                            // Create and dispatch input event
                            const event = new Event("input", { bubbles: true });
                            element.dispatchEvent(event);
                        } else {
                            console.warn(`Element not found for ${field.formName}`);
                        }
                    });
                });
            }, 100);
        }
    }, [convertArrayOfQueryToObject]);

    const onFormFinish = async (event, btnStatus = false, isAutoSave = false) => {
        try {
            if (customFinishBtnLoader.current) return;
            if (isAutoSave) {
                clearTimeout(timeoutIdRef.current);
            }
            if (event) {
                event?.preventDefault();
            }
            
            // For Person form, look for bg_body class instead of page
            const pages = htmlType === "PERSON" 
                ? document.getElementsByClassName("bg_body")
                : document.getElementsByClassName("page");

            if (!pages || pages.length === 0) {
                throw new Error("No pages found to save");
            }

            let tabs = [];
            
            if (htmlType === "NURSE") {
                try {
                    const cssResponse = await fetch('/statichtmlPages/nurse-form/nurse-style.css');
                    const cssText = await cssResponse.text();
                    
                    // Add fixes for container width and margins
                    const fixedCSS = `
                        @page {
                            size: A4;
                            margin: 5mm !important;
                        }
                        body {
                            margin: 0 !important;
                            padding: 0 !important;
                            width: 100% !important;
                            overflow-x: hidden !important;
                            -webkit-print-color-adjust: exact !important;
                            print-color-adjust: exact !important;
                        }
                        .container {
                            width: 100% !important;
                            max-width: none !important;
                            margin: 0 auto !important;
                            padding: 5mm !important;
                            box-sizing: border-box !important;
                        }
                        .main_subList {
                            width: 100% !important;
                            table-layout: fixed !important;
                            margin: 0 !important;
                        }
                        #page-container {
                            width: 100% !important;
                            margin: 0 auto !important;
                            overflow: visible !important;
                        }
                        .page {
                            width: 100% !important;
                            margin: 0 auto !important;
                            padding: 0 !important;
                            overflow: visible !important;
                        }
                        @media print {
                            html, body {
                                width: 210mm !important;
                                height: 297mm !important;
                            }
                            .page {
                                width: 210mm !important;
                                margin: 0 !important;
                                padding: 5mm !important;
                            }
                        }
                    `;

                    const style = document.createElement('style');
                    style.textContent = cssText + fixedCSS;
                    document.head.appendChild(style);

                    for (let i = 0; i < pages.length; i++) {
                        const pageClone = pages[i].cloneNode(true);
                        
                        // Enhanced textarea handling
                        const textareas = pageClone.querySelectorAll('.expanding-textarea');
                        textareas.forEach(textarea => {
                            if (textarea.value) {
                                // Convert the textarea content properly
                                const text = textarea.value;
                                textarea.textContent = text; // This will properly escape HTML
                                
                                // Calculate proper height
                                const lines = text.split('\n').length;
                                const lineHeight = parseInt(getComputedStyle(textarea).lineHeight);
                                const minHeight = Math.max(lines * lineHeight, 50);
                                
                                // Set styles
                                textarea.style.cssText = `
                                    min-height: ${minHeight}px !important;
                                    height: auto !important;
                                    white-space: pre-wrap !important;
                                    word-wrap: break-word !important;
                                    line-height: 1.5 !important;
                                    padding: 4px !important;
                                    display: block !important;
                                `;
                            }
                        });

                        // Enhanced CSS for textarea handling
                        const additionalCSS = `
                            .expanding-textarea {
                                white-space: pre-wrap !important;
                                word-wrap: break-word !important;
                                line-height: 1.5 !important;
                                padding: 4px !important;
                                display: block !important;
                            }
                            textarea {
                                font-family: inherit !important;
                                font-size: inherit !important;
                            }
                            td {
                                vertical-align: top !important;
                                padding: 8px !important;
                            }
                            @media print {
                                .expanding-textarea {
                                    overflow: visible !important;
                                    height: auto !important;
                                    white-space: pre-wrap !important;
                                }
                                td {
                                    page-break-inside: avoid !important;
                                }
                            }
                        `;

                        tabs.push({
                            type: "html",
                            pageNumber: i + 1,
                            value: `
                                <!DOCTYPE html>
                                <html lang="en">
                                <head>
                                    <meta charset="UTF-8">
                                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                                    <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
                                    <style>
                                        ${cssText}
                                        ${additionalCSS}
                                        ${fixedCSS}
                                    </style>
                                </head>
                                <body>
                                    <div style="max-width: 210mm; margin: 0 auto; overflow: hidden;">
                                        ${pageClone.outerHTML}
                                    </div>
                                    <script>
                                        document.addEventListener('DOMContentLoaded', function() {
                                            document.querySelectorAll('.expanding-textarea').forEach(function(textarea) {
                                                // Ensure proper text rendering
                                                textarea.style.whiteSpace = 'pre-wrap';
                                                textarea.style.height = 'auto';
                                                textarea.style.height = textarea.scrollHeight + 'px';
                                            });
                                        });
                                    </script>
                                </body>
                                </html>
                            `
                        });
                    }
                } catch (error) {
                    console.error("Error loading CSS:", error);
                    message.error("Error loading CSS for Nurse form");
                }
            } else if (htmlType === "PERSON") {
                try {
                    const cssResponse = await fetch('/statichtmlPages/person-individual-profile/person-style.css');
                    const cssText = await cssResponse.text();
                    
                    // Add print-specific CSS for landscape orientation
                    const printCSS = `
                        @media print {
                            @page {
                                size: landscape;
                                margin: 0;
                            }
                            body {
                                margin: 0;
                                padding: 0;
                            }
                            .bg_body {
                                width: 100% !important;
                                height: 100% !important;
                                margin: 0 !important;
                                padding: 10px !important;
                                transform: none !important;
                            }
                            .table_main {
                                width: 100% !important;
                                margin: 0 !important;
                                max-width: none !important;
                            }
                            .set_center_table {
                                width: 100% !important;
                                overflow: visible !important;
                            }
                        }
                    `;
                    
                    for (let i = 0; i < pages.length; i++) {
                        const pageClone = pages[i].cloneNode(true);
                        
                        // Preserve input values
                        const inputs = pageClone.querySelectorAll('input[type="text"], input[type="date"], textarea');
                        inputs.forEach(input => {
                            if (input.value) {
                                input.setAttribute('value', input.value);
                                // For textareas, we need to set both value and innerHTML
                                if (input.tagName.toLowerCase() === 'textarea') {
                                    input.innerHTML = input.value;
                                }
                            }
                        });

                        // Preserve checkbox states
                        const checkboxes = pageClone.querySelectorAll('input[type="checkbox"]');
                        checkboxes.forEach(checkbox => {
                            if (checkbox.checked) {
                                checkbox.setAttribute('checked', 'checked');
                            }
                        });

                        // Preserve expanding-textarea values
                        const textareas = pageClone.querySelectorAll('.expanding-textarea');
                        textareas.forEach(textarea => {
                            if (textarea.value) {
                                textarea.innerHTML = textarea.value;
                                textarea.style.height = 'auto';
                                textarea.style.height = textarea.scrollHeight + 'px';
                            }
                        });

                        tabs.push({
                            type: "html",
                            pageNumber: i + 1,
                            value: `
                                <!DOCTYPE html>
                                <html lang="en">
                                <head>
                                    <meta charset="UTF-8">
                                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                                    <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
                                    <style>
                                        @page {
                                            size: A3 landscape;
                                            margin: 0;
                                        }
                                        html {
                                            width: 100%;
                                            height: 100%;
                                            overflow: visible;
                                        }
                                        body {
                                            width: 100%;
                                            height: 100%;
                                            margin: 0;
                                            padding: 0;
                                            -webkit-print-color-adjust: exact;
                                            print-color-adjust: exact;
                                        }
                                        ${cssText}
                                        #page-container {
                                            width: 100%;
                                            height: 100%;
                                            margin: 0;
                                            padding: 0;
                                        }
                                        .bg_body {
                                            width: 100% !important;
                                            height: 100% !important;
                                            margin: 0 !important;
                                            padding: 20px !important;
                                            background-color: white !important;
                                        }
                                        .table_main {
                                            width: 100% !important;
                                            max-width: none !important;
                                            margin: 0 auto !important;
                                        }
                                        .set_center_table {
                                            width: 100% !important;
                                        }
                                    </style>
                                </head>
                                <body>
                                    <div id="page-container">
                                        ${pageClone.outerHTML}
                                    </div>
                                    <script>
                                        // Restore textarea heights on load
                                        document.addEventListener('DOMContentLoaded', function() {
                                            const textareas = document.querySelectorAll('.expanding-textarea');
                                            textareas.forEach(textarea => {
                                                textarea.style.height = 'auto';
                                                textarea.style.height = textarea.scrollHeight + 'px';
                                            });
                                        });
                                    </script>
                                </body>
                                </html>
                            `
                        });
                    }
                } catch (error) {
                    console.error("Error loading CSS:", error);
                    message.error("Error loading CSS for Person form");
                }
            } else {
                // Handle other form types as before
                for (let i = 0; i < pages.length; i++) {
                    tabs.push({
                        type: "html",
                        pageNumber: i + 1,
                        value: pages[i].outerHTML
                    });
                }
            }
            
            tabs = [...tabs, ...fields];
            
            customFinishBtnLoader.current = true;
            updateEnvelopeCall(tabs, btnStatus, isAutoSave);
            
        } catch (error) {
            console.error("Error saving form:", error);
            message.error("Failed to save form. Please check console for details.");
        }
    };

    // const onDownload = () => {
    // const input = pdfRef.current;
    // html2canvas(input).then((canvas) => {
    //     const imgData = canvas.toDataURL("image/png");
    //     const pdf = new jsPDF("p", "mm", "a4", true);
    //     const pdfWidth = pdf.internal.pageSize.getWidth();
    //     const pdfHeight = pdf.internal.pageSize.getHeight();
    //     const imgWidth = canvas.width;
    //     const imgHeight = canvas.height;
    //     const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
    //     const imgX = (pdfWidth - imgWidth * ratio) / 2;
    //     const imgY = 30;
    //     pdf.addImage(imgData, "PNG", imgX, imgY, imgWidth * ratio, imgHeight * ratio);
    //     pdf.save("form.pdf");
    // });
    // window.print();
    // };

    const updateEnvelopeCall = (tabsFields, isFinishLaterBtn = false, isAutoSave = false) => {
        if (showButtons == 1) {
            return;
        }

        let findNameField;
        let findEmailField;

        if (data) {
            const queryNameFields = UtilService.base64toArray(data);
            findNameField = queryNameFields.find(
                (singleField) => singleField.queryName === FIELD_TYPES.signerName
            )?.value;
            findEmailField = queryNameFields.find(
                (singleField) => singleField.queryName === FIELD_TYPES.signerEmail
            )?.value;
        }

        if (checkUserEnvelopeGeneratedFormView) {
            const headers = [
                {
                    headerKey: envelopeHeaderKey.refreshToken,
                    headerValue: refresh_token
                },
                {
                    headerKey: envelopeHeaderKey.xEnvelope,
                    headerValue: isDraftEnvelope.current?.x_envelope ?? x_envelope
                },
                {
                    headerKey: envelopeHeaderKey.clientApiKey,
                    headerValue: client_api_key
                }
            ];
            const payload = {
                tabs: tabsFields,
                name: `${name ?? ""} ${id}`,
                action: envelopeActionEnum.envelopeFinished,
                webhook_data: decodedWebhookData,
                envelopeAction: isDraftEnvelope?.current?.check
                    ? ActionsTypesForGuestRoutes.UPDATE_ENVELOPE
                    : ActionsTypesForGuestRoutes.CREATE_ENVELOPE,
                email: ""
            };
            if (isFinishLaterBtn) {
                payload.action = envelopeActionEnum.EnvelopeFinishLater;
            }

            if (Boolean(envelopeIdParam) && Boolean(actionParam)) {
                payload.name = signingData?.attributes?.name;
                payload.email = signingData?.attributes?.email;

                if (actionParam === ACTIONS_PARAM_NAMES_ENVELOPES.CLONE_ENVELOPE && updateCloneAction.current) {
                    payload.envelopeAction = ACTIONS_PARAM_NAMES_ENVELOPES.CLONE_ENVELOPE;
                    updateCloneAction.current = false;
                } else if (actionParam === ACTIONS_PARAM_NAMES_ENVELOPES.UPDATE_ENVELOPE) {
                    payload.envelopeAction = ACTIONS_PARAM_NAMES_ENVELOPES.UPDATE_ENVELOPE;
                }
            }

            if (findNameField) {
                payload.name = findNameField;
            }
            if (findEmailField) {
                payload.email = findEmailField;
            }

            if (isAutoSave) {
                payload.statusAction = EnvelopeActionStatus.DRAFT;
                isDraftEnvelope.current = {
                    ...isDraftEnvelope.current,
                    check: true
                };
            }

            try {
                setButtonDisable(true);

                createEnvelopeByPassedRouteMutateQuery(
                    { payload, headers },
                    {
                        onSuccess: (data) => {
                            createEnvelopeSuccess(data, isAutoSave);

                            const authorizeToken = data?.attributes?.authorizeToken;

                            if (isAutoSave && Boolean(authorizeToken)) {
                                isDraftEnvelope.current = {
                                    ...isDraftEnvelope.current,
                                    x_envelope: authorizeToken
                                };
                            }
                        },
                        onSettled: (data) => {
                            customFinishBtnLoader.current = false;
                        }
                    }
                );
            } catch (error) {
                console.log("error", error);
            } finally {
                setButtonDisable(false);
            }
        }
    };

    const createEnvelopeSuccess = (data, isAutoSave = false) => {
        message.success(`envelope is  ${isAutoSave ? "auto " : ""}saved successfully!`);

        if (!isAutoSave) {
            const connectReturnUrl = data?.attributes?.connectReturnUrl;
            const addParams = `?env=${data?.attributes?.identity_key}`;
            const connectReturnUrlTwo = `${connectReturnUrl}${addParams}`;
            const connectReturnUrlThree = `${normalRoutesConstant.ThankYou}${addParams}`;
            if (Boolean(send_email)) {
                window.location.href = connectReturnUrlThree;
            } else {
                const exactRedirectUrl = connectReturnUrl ? connectReturnUrlTwo : connectReturnUrlThree;
                window.location.href = exactRedirectUrl;
            }
        }
    };

    const showSignatureModal = (event, singleField) => {
        event.preventDefault();
        setSignaturePopup(true);
        setSignatureField(singleField);
        // getRecentSignatures();
    };

    const addSignature = (signatureValue) => {
        const currentDate = moment().format("MM-DD-yyyy h:mm a");
        const updateField = fields.map((field) => {
            if (field.id === signatureField.id) {
                return { ...field, value: signatureValue, currentDateTime: currentDate };
            }
            return field;
        });
        setFields(updateField);
    };

    const trackPositions = (data, singleFieldParam) => {
        const updateField = fields.map((field) => {
            if (field.id === singleFieldParam.id) {
                return { ...field, x: data?.x, y: data?.y + window.scrollY };
            }
            return field;
        });
        setFields(updateField);
        // dispatch(
        //     updateField({
        //         id: singleFieldParam.id,
        //         x: data?.x,
        //         y: data?.y
        //     })
        // );
    };

    return (
        <>
            <div>
                <Helmet>
                    <script
                        src={`/statichtmlPages/${scriptSrc}`}
                        async
                        type="application/javascript"
                    />
                    <script
                        src="/html-signature-modal-script.js"
                        async
                        type="application/javascript"
                        id="signature-modal-script"
                    />
                    {cssSrc && <link rel="stylesheet" href={`/statichtmlPages/${cssSrc}`} />}
                </Helmet>

                {/* {showButtons == 1 && (
                    <div style={{ display: "flex", justifyContent: "flex-end", gap: "1rem", margin: "12px 0px" }}>
                        <Button type="primary" onClick={onDownload} className="common-button envelope-btn print-button">
                            Download
                        </Button>
                    </div>
                )} */}

                {showButtons != 1 && (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            flexDirection: wide ? "row" : "column",
                            gap: "1rem",
                            margin: "12px auto",
                            textAlign: "center",
                            maxWidth: "900px",
                            position: "sticky",
                            top: 0,
                            zIndex: 10,
                            padding: "9px 12px",
                            background: "rgba(255,255,255,0.4)",
                            borderRadius: "2px",
                            boxShadow: "0 4px 30px rgba(0,0,0,0.1)",
                            backdropFilter: "blur(5px)",
                            border: "1px solid rgba(255,255,255,0.3)",
                            alignItems: "center"
                        }}
                    >
                        <div
                            style={{
                                flex: 1,
                                color: "black",
                                background: "#fffbe6",
                                padding: "8px 12px",
                                borderRadius: 5,
                                border: "1px solid #ffe58f"
                            }}
                        >
                            *if your are working with large data please make sure that you save occasionally
                        </div>
                        <div style={{ display: "flex", gap: "1rem" }}>
                            <Button
                                type="primary"
                                onClick={onFormFinish}
                                disabled={buttonDisable}
                                className="common-button envelope-btn"
                                loading={customFinishBtnLoader.current || updateEnvelopeByPassedLoading}
                            >
                                Finish
                            </Button>

                            {!review && !Boolean(send_email) && (
                                <>
                                    {restrictions !== "1" && (
                                        <Button
                                            type="primary"
                                            onClick={(event) => onFormFinish(event, FINISH_LATER_BTN_CHECK)}
                                            className="common-button envelope-btn envelope-btn-finish-later"
                                            disabled={
                                                customFinishBtnLoader.current ||
                                                updateEnvelopeByPassedLoading ||
                                                buttonDisable
                                            }
                                        >
                                            Save Work
                                        </Button>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                )}
                <div ref={pdfRef}>
                    <DndProvider backend={HTML5Backend}>
                        {send_email && (
                            <div style={{ position: "fixed" }}>
                                <DraggableSignatureBox setFields={setFields} />
                            </div>
                        )}
                        <div
                            style={{
                                width: htmlType === "PERSON" ? "1800" : "920px",
                                margin: "0 auto",
                                ...(showButtons == 1 && { pointerEvents: "none" })
                            }}
                        >
                            <HTMLDropWrapper setFields={setFields}>
                                <div
                                    style={{ 
                                        width: htmlType === "PERSON" ? "1800px" : "920",
                                        margin: "0 auto",
                                        transform: htmlType === "PERSON" ? "rotate(0deg)" : "none"
                                    }}
                                    id="page-container"
                                    className={`${
                                        Boolean(is_document_edit == "" || is_document_edit == "false")
                                            ? "input-disabled"
                                            : ""
                                    } ${htmlType === "PERSON" ? "landscape-mode" : ""}`}
                                >
                                    {fields?.map((field) => {
                                        return (
                                            <PrintSignatureField
                                                field={field}
                                                key={field.id}
                                                showSignatureModal={showSignatureModal}
                                                trackPositions={trackPositions}
                                                setFields={setFields}
                                                fields={fields}
                                            />
                                        );
                                    })}
                                    <div ref={targetElementRef}>
                                        {tabs &&
                                            type === "HTML" &&
                                            tabs.map((tab, index) => {
                                                if (tab?.type === "html") {
                                                    return (
                                                        <div
                                                            // ref={targetElementRef}
                                                            key={index}
                                                            dangerouslySetInnerHTML={{
                                                                __html: parser.parseFromString(tab.value, "text/html")
                                                                    .documentElement.outerHTML
                                                            }}
                                                        />
                                                    );
                                                }
                                            })}
                                    </div>
                                </div>
                            </HTMLDropWrapper>
                        </div>
                    </DndProvider>
                </div>
            </div>
            <CustomSignatureModal
                setSignaturePopup={setSignaturePopup}
                signaturePopup={signaturePopup}
                addSignature={addSignature}
            />
        </>
    );
}

export default HTMLEnvelopeFormView;

const useWindowWide = (size) => {
    const [width, setWidth] = useState(0);

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
    return width > size;
        };
    }, [setWidth]);

    return width > size;
};




